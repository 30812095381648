import React from "react";
import pdfImg from "../../assets/images/muhs/pdfImg.png";
import "../../style/AdmissionPage/headerSection.css";
import { studentOnline, CapabilityEnhancementandDevelopmentPrograms, CareerGuidanceCellUjjwalBhavishyaInitiative, ActionTakenReportforStudentFeedback } from "../../content/Admission/ProspectusInformation";

const Prospectus = () => {
  return (
    <div className="border-2 border-black p-4" id="prosPectus">
      <section className="font-fam flex items-center justify-center gap-3 flex-col">
        <h2 className="text-3xl tracking-wide font-bold ">Capability Enhancement and Development Programs</h2>
        {CapabilityEnhancementandDevelopmentPrograms.map((item) => (
          <button className="bg-[#FFD900]  flex items-center justify-center p-2 md:p-3">
            <img src={pdfImg} alt="PDF" className="mr-2" />
            <span key={item.id} className="tracking-wide text-blue-500">
              <a href={item.pdfLink} target="_blank" rel="noreferrer">
                Download PDF
              </a>
            </span>
          </button>
        ))}
      </section>

      <section className="font-fam flex items-center justify-center mt-3 gap-3 flex-col">
        <h2 className="text-3xl tracking-wide font-bold ">Career Guidance Cell Ujjwal Bhavishya Initiative</h2>
        {CareerGuidanceCellUjjwalBhavishyaInitiative.map((item) => (
          <button className="bg-[#FFD900]  flex items-center justify-center p-2 md:p-3">
            <img src={pdfImg} alt="PDF" className="mr-2" />
            <span key={item.id} className="tracking-wide text-blue-500">
              <a href={item.pdfLink} target="_blank" rel="noreferrer">
                Download PDF
              </a>
            </span>
          </button>
        ))}
      </section>

      <section className="font-fam flex items-center justify-center mt-3 gap-3 flex-col">
        <h2 className="text-3xl tracking-wide font-bold ">Action Taken Report for Student Feedback</h2>
        {ActionTakenReportforStudentFeedback.map((item) => (
          <button className="bg-[#FFD900]  flex items-center justify-center p-2 md:p-3">
            <img src={pdfImg} alt="PDF" className="mr-2" />
            <span key={item.id} className="tracking-wide text-blue-500">
              <a href={item.pdfLink} target="_blank" rel="noreferrer">
                Download PDF
              </a>
            </span>
          </button>
        ))}
      </section>
      <section className="font-fam flex items-center justify-center mt-3 gap-3 flex-col">
        <h2 className="text-3xl tracking-wide font-bold ">Student Online</h2>
        {studentOnline.map((item) => (
          <button className="bg-[#FFD900]  flex items-center justify-center p-2 md:p-3">
            <img src={pdfImg} alt="PDF" className="mr-2" />
            <span key={item.id} className="tracking-wide text-blue-500">
              <a href={item.pdfLink} target="_blank" rel="noreferrer">
                Download PDF
              </a>
            </span>
          </button>
        ))}
      </section>
    </div>
  );
};

export default Prospectus;
