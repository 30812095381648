import React, { useState } from "react";
import "../../style/AdmissionPage/headerSection.css";
import { muhs } from "../../content/MuhsMandate/MUHS";

function Muhs() {
  const [selectedYear, setSelectedYear] = useState("2022-2023");

  const handleColor = (year) => {
    setSelectedYear(year);
  };

  return (
    <div className="">
      <section className="bg-[#800000] w-full relative">
        <div className="flex items-center justify-center w-full p-5">
          <div className="w-full md:w-1/2 m-3 md:m-5">
            <div className="w-full md:border-l-2 md:border-[#FFD900]">
              <section className="mx-6 flex items-center gap-3 mb-4">
                <h1 className="text-5xl font-bold font-fam text-white tracking-wide md:ml-12">
                  MUHS
                  <br />
                  Mandate
                </h1>
              </section>
            </div>
          </div>

          <div className="md:w-1/2 hidden md:block gradinetStyle"></div>
        </div>
      </section>

      <section className="absolute border-white border-2 rounded-full md:bottom-[3%]  md:left-8 drop-shadow-lg flex items-center justify-between bg-gray-100  w-[100%] md:w-[30%]">
        <div
          className={`p-2 md:px-10 rounded-l-full md:p-6 flex items-center justify-center  cursor-pointer w-[33.33%] ${
            selectedYear === "2022-2023" && "bg-[#FFD900]"
          } `}
          onClick={() => handleColor("2022-2023")}
        >
          <h3 className="font-fam font-bold tracking-wide">2022-2023</h3>
        </div>
        <div
          className={`p-2 md:px-10 md:p-6 flex items-center justify-center hover:bg-[#FFD900] cursor-pointer w-[33.33%] ${
            selectedYear === "2023-2024" && "bg-[#FFD900]"
          } `}
          onClick={() => handleColor("2023-2024")}
        >
          <h3 className="font-fam font-bold tracking-wide">2023-2024</h3>
        </div>
        <div
          className={`p-2 md:px-10 rounded-r-full md:p-6 flex items-center justify-center hover:bg-[#FFD900] cursor-pointer w-[33.33%] ${
            selectedYear === "2024-2025" && "bg-[#FFD900]"
          } `}
          onClick={() => handleColor("2024-2025")}
        >
          <h3 className="font-fam font-bold tracking-wide">2024-2025</h3>
        </div>
      </section>

      <section
        className="flex items-center justify-center w-full"
        style={{
          marginTop: "4.25rem",
          marginBottom: "4.25rem",
        }}
      >
        <section className="bg-[#D9DEE6] w-full md:w-[90%]">
          {muhs.find((year) => year.year === selectedYear)?.sub.map((item) => (
            <div
              className="bg-white m-2 md:m-4 px-4 flex items-center justify-between rounded"
              key={item.name}
            >
              <h5 className="font-bold font-fam  md:text-lg w-[60%] my-3 border-r-2  border-black">
                {item.name}
              </h5>
              <div className="w-[60%] md:w-[40%] flex items-center justify-center">
                <button className="bg-[#FFD900]  flex items-center justify-center p-2 md:p-3">
                  <img src={'https://files.yashdental.org/wp-content/uploads/2023/04/pdfImg.png'} alt="PDF" className="mr-2" />
                  <span className="tracking-wide text-blue-500">
                    <a href={item.pdf} target="_blank" rel="noreferrer">
                      View PDF
                    </a>
                  </span>
                </button>
              </div>
            </div>
          ))}
        </section>
      </section>
    </div>
  );
}

export default Muhs;
