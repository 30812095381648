export const _2_1_1 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _2_1_2 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _2_1_3 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _2_2_1 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _2_2_2 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _2_2_3 = [
  {
    name: "Link for Appropriate documentary evidence",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.2.3-Link-for-Appropriate-documentary.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.2.3-Link-for-any-other-relevant-information.pdf",
  },
];

export const _2_3_1 = [
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.3.1-Link-for-learning-environment-facilities-with-geotagging.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/2.3.1-Link-for-any-other-relevant-information.pdf",
  },
];

export const _2_3_2 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _2_3_3 = [
  {
    name: "Link for webpage describing the “LMS/ Academic Management System”",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/2.3.3-Link-for-webpage-describing-the-LMS-Academic-Management-System.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/2.3.3-bullet-4-any-other-relevant-information.docx",
  },
  {
    name: "File for list of teachers using ICT-enabled tools (including LMS)",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/2.3.3_1684321732_9034.pdf",
  },
  {
    name: "File for details of ICT-enabled tools used for teaching and learning",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/2.3.3_1685950647_9034.pdf",
  }
];

export const _2_3_4 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _2_3_5 = [
  {
    name: "Link for appropriate documentary evidence",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.3.5-Link-for-appropriate-documentary-evidence.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.3.5.Link-for-any-other-relevant-information.pdf",
  },
];

export const _2_4_1 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _2_4_2 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _2_4_3 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _2_4_4 = [
  {
    name: "Web-link to the contents delivered by the faculty hosted in the HEI’s website",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/2.4.4-Landing-Page-and-Weblinks.pdf",
  },
  {
    name: "Link for additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.4.4-Link-for-additional-information.pdf",
  },
];

export const _2_4_5 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _2_5_1 = [
  {
    name: "Link for dates of conduct of internal assessment examinations",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/2.5.1-Link-for-dates-of-conduct-of-internal-assessment-examinations.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/2.5.1-Link-for-any-other-relevant-information-SOP.pdf",
  },
  {
    name: "Link for academic calendar",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/2.5.1-Academic-Calendar-of-last-five-years.pdf",
  }
];

export const _2_5_2 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _2_5_3 = [
  {
    name: "Link for Information on examination reforms",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.5.3-Link-for-Information-on-examination-reforms.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.5.3-Link-for-any-other-relevant-information.pdf",
  },
];

export const _2_5_4 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _2_6_1 = [
  {
    name: "Link for upload Course Outcomes for all courses (exemplars from Glossary)",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/2.6.1-Link-for-upload-Course-Outcomes-for-all-courses-exemplars-from-Glossary.pdf",
  },
  {
    name: "Link for relevant documents pertaining to learning outcomes and graduate attributes",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.6.1-Link-for-relevant-documents-pertaining-to-learning-outcomes-and-graduate-attributes.pdf",
  },
  {
    name: "Link for methods of the assessment of learning outcomes and graduate attributes",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.6.1-Link-for-methods-of-the-assessment-of-learning-outcomes-and-graduate-attributes.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.6.1-B34-Link-for-any-other-relevant-information-DCI.pdf",
  },
];

export const _2_6_2 = [
  {
    name: "Links for additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.6.2-List-of-Meritorious-Students-in-Last-five-years-.pdf",
  },
  {
    name: "Link for the annual report of examination results as placed before BoM/ Syndicate/ Governing Council for the last five years",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/2.6.2-Link-for-the-annual-report.pdf",
  },
];

export const _2_6_3 = [
  {
    name: "Link for programme-specific learning outcomes",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.6.3-Link-for-programme-specific-learning-outcomes.pdf",
  },
  {
    name: "Link for programme-specific learning outcomes",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/2.6.3-Link-for-any-other-relevant-information.pdf",
  },
];

export const _2_6_4 = [
  {
    name: "Link for proceedings of parent –teachers meetings held during the last 5 years",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/2.6.4-Link-for-proceedings.pdf",
  },
  {
    name: "Link for follow up reports on the action taken and outcome analysis.",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.6.4-Link-for-follow-up-reports-on-the-action-taken-and-outcome-analysis.pdf ",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/2.6.4-Link-for-any-other-relevant-information.pdf",
  },
];

export const _2_7_1 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];











