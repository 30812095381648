export const oralSurgeryLink = [
    {
      name: "Dr. Jaggi Nitin",
      position: "Prof. & HOD",
    },
    {
      name: "Dr. Anand K.P",
      position: "Reader",
    },
    {
      name: "Dr. Mulay Abhishek",
      position: "Reader",
    },
    {
      name: "Dr. Rothe Tushar",
      position: "Lecturer",
    },
    {
      name: "Dr. Patel Summaya",
      position: "Lecturer",
    },
    {
      name: "Dr. Anand Rahul",
      position: "Lecturer",
    },
    {
      name: "Dr. Singh Trupti",
      position: "Tutor",
    },
    {
      name: "Dr. Geetanjali Nikam",
      position: "Tutor",
    },
  ];