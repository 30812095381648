export const pedodonticsLink = [
  {
    name: "Dr. Raorale Ashish",
    position: "Reader& HOD",
  },
  {
    name: "Dr. Shailee Shelke",
    position: "Lecturer",
  },
  {
    name: "Dr. Pargaonkar Sudarshan",
    position: "Lecturer",
  },
  {
    name: "Dr. Trupti Dungarwal",
    position: "Lecturer",
  },
  {
    name: "Dr. Pranav Dungarwal",
    position: "Lecturer",
  },
  {
    name: "Dr. Aghav Sharad",
    position: "Tutor",
  },
  {
    name: "Dr. Shinde Sayali",
    position: "Tutor",
  },
  {
    name: "Dr. Varma Rajashree",
    position: "Tutor",
  },
];
