export const _1_1_1 = [
  {
    name: "Link for Minutes of the meeting of the college curriculum committee",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/1.1.1-CCC-MOM.pdf ",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/1.1.1-IAAC-MOM.pdf ",
  },
  {
    name: "Any additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/1.1.1-teaching-plan-21-22.pdf ",
  },
];

export const _1_2_1 = [
  {
    name: "Link for Additional Information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/1.2.1-PO-MAPPING.pdf ",
  },
];
export const _1_2_2 = [
  {
    name: "Link for additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/1.2.2-add-on-reports.pdf ",
  },
  {
    name: "Details of the students enrolled in subject-related Certificate/Diploma/Add-on courses",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/1.2.2-Add-on-student-list.pdf ",
  },
  {
    name: "Any additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/1.2.2.Add-on-Cert_Final.pdf ",
  },
];

export const _1_3_1 = [
  {
    name: "Link for list of courses with their descriptions",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/1.3.1-B1-list-of-courses.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/1.3.1-B2.pdf ",
  },
];

// export const _1_3_2 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
//   {
//     name: "Criteria 1.2 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];

// export const _1_3_3 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
//   {
//     name: "Criteria 1.2 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];

export const _1_3_4 = [
  {
    name: "Links to scanned copy of completion certificate of field visits/Clinical / industry internships/research projects/industry visits/community postings from the organization where internship was completed",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/1.3.4-certificates.pdf ",
  },
  {
    name: "Link for additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/1.3.4_INTERNS-LIST-21-22.pdf",
  },
];

export const _1_4_1 = [
  {
    name: "Stakeholder feedback reports as stated in the minutes of meetings of the College Council /IQAC/ Curriculum Committee",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/1.4.1-IQAC-FINAL.pdf ",
  },
];

export const _1_4_2 = [
  {
    name: "Stakeholder feedback report",
    url: " https://files.yashdental.org/wp-content/uploads/2023/05/1.4.2FeedbackAnalysis.pdf",
  },
  {
    name: "Link for additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/Action-Taken-Report-for-Student-Feedback.pdf ",
  },
];
