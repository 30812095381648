export const generalPathologyLink = [
    {
      name: "Dr. Zarekar Sarika",
      position: "Reader & HOD",
    },
    {
      name: "Dr. Joshi Gauri",
      position: "Lecturer",
    },
    {
      name: "Dr. Bhandare Ashok",
      position: "Lecturer",
    },
    {
      name: "Dr. Suryawanshi Sampat",
      position: "Lecturer",
    },
  ];