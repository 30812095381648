// export const cr3_1_1 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];
// export const cr3_1_2 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];
// export const cr3_1_3 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];

export const cr3_2_1 = [
  {
    name: "Link for details of the facilities and innovations made",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/3.2.1-LinkForDetailFacilities.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/3.2.1-02-OtherRelevantInfo-All.pdf",
  },
];
// export const cr3_2_2 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];
// export const cr3_2_3 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];
// export const cr3_3_1 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];
// export const cr3_3_2 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];
export const cr3_3_3 = [
  {
    name: "Web-link provided by institution",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/3.3.3-WeblinkProvidedTemplate-FINAL-Copy.xlsx ",
  },
];
// export const cr3_3_4 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];

// export const cr3_4_1 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];
// export const cr3_4_2 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];
export const cr3_4_3 = [
  {
    name: "Link for list of awards for extension activities in the last 5 year",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/3.4.3-B1.pdf ",
  },
  {
    name: "Link for e-copies of the award letters",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/3.4.3-B2.pdf ",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/3.4.3-Any-other-relevant-Final.pdf",
  },
];
export const cr3_4_4 = [
  {
    name: "Link for details of Institutional social responsibility activities in the neighbourhood community during the last 5 years",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/3.4.4-B1.pdf ",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/34.4AnyOther_Final.pdf",
  },
];
// export const cr3_5_1 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];
// export const cr3_5_2 = [
//   {
//     name: "Criteria 1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];
