export const orthodonticsLink = [
    {
      name: "Dr. Patil Tushar",
      position: "Prof. & HOD",
    },
    {
      name: "Dr. Mahamuni Avinash",
      position: "Prof.",
    },
    {
      name: "Dr. Misal Abhijit",
      position: "Reader",
    },
    {
      name: "Dr. Nawale Priyanka",
      position: "Reader",
    },
    {
      name: "Dr. Rai Rajlaxmi",
      position: "Reader",
    },
    {
      name: "Dr. Pawar Vaibhav",
      position: "Lecturer",
    },
    {
      name: "Dr. Pathak Snehal",
      position: "Lecturer",
    },
    {
      name: "Dr. Reyali Gajare",
      position: "Lecturer",
    },
    {
      name: "Dr. Kanchan Purswani",
      position: "Lecturer",
    },
    {
      name: "Dr. Lokhande Rajkiran",
      position: "Lecturer",
    },
    {
      name: "Dr. Shivale Shrikant",
      position: "Lecturer",
    },
    {
      name: "Dr. Harsha Mahajan",
      position: "Lecturer",
    },
    {
      name: "Dr. Rajput Raksha",
      position: "Tutor",
    },
    {
      name: "Dr. Gosavi Rohini",
      position: "Tutor",
    },
    {
      name: "Dr. Chavan Mayuri",
      position: "Tutor",
    },
  ];