export const oralPathologyLink = [
    {
      name: "Dr. Rajput Dinesh",
      position: "Prof. & HOD",
    },
    {
      name: "Dr. Rathi Ruchi",
      position: "Tutor",
    },
    {
      name: "Dr. Darak Neha",
      position: "Tutor",
    },
    {
      name: "Dr. Nimbalkar Reshma",
      position: "Tutor",
    },
    {
      name: "Dr. Bommanavar Sushma",
      position: "Reader",
    },
    {
      name: "Dr. Shirke Ketaki",
      position: "Lecturer",
    },
  ];
  