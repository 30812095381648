export const generalMedicineLink = [
    {
      name: "Dr. Jagdale Manoj",
      position: "Reader & HOD",
    },
    {
      name: "Dr. Borude Sagar",
      position: "Lecturer",
    },
    {
      name: "Dr. Kangune Avinash",
      position: "Lecturer",
    },
    {
      name: "Dr. Thorat Rohit",
      position: "Lecturer",
    },
  ];
  