export const carousel1 = [
  {
    caroselImg:
      "https://files.yashdental.org/wp-content/uploads/2023/04/1-scaled.webp",
  },
  {
    caroselImg:
      "https://files.yashdental.org/wp-content/uploads/2023/04/2.webp",
  },
  {
    caroselImg:
      "https://files.yashdental.org/wp-content/uploads/2023/04/newCoroselImg-scaled.webp",
  },
  {
    caroselImg:
      "https://files.yashdental.org/wp-content/uploads/2023/04/3.webp",
  },
  {
    caroselImg:
      "https://files.yashdental.org/wp-content/uploads/2023/04/4.webp",
  },
  {
    caroselImg:
      "https://files.yashdental.org/wp-content/uploads/2023/04/5.webp",
  },
  {
    caroselImg:
      "https://files.yashdental.org/wp-content/uploads/2023/04/6.webp",
  },
];
