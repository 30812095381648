export const cr4_1_1 = [
  {
    name: "Link for list of available teaching-learning facilities such as Classrooms, Laboratories, ICT enabled facilities including Teleconference facilities etc., mentioned above.",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/Link-for-list-of-available-teaching-learning-facilities-such-as-Classrooms-Laboratories-ICT-enabled-facilities-including-Teleconference-facilities-etc-mentioned-above.pdf ",
  },
  {
    name: "Link for geotagged photographs",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.1.1-geotagged-photographs.pdf ",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/Link-for-list-of-available-teaching-learning-facilities-such-as-Classrooms-Laboratories-ICT-enabled-facilities-including-Teleconference-facilities-etc-mentioned-above.pdf ",
  },
];
export const cr4_1_2 = [
  {
    name: "Link for list of available sports and cultural facilities",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/Link-for-list-of-available-sports-and-cultural-facilities.pdf ",
  },
  {
    name: "Link for geotagged photographs",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.1.2-Geotag-Photos-Final.pdf ",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/Link-for-list-of-available-sports-and-cultural-facilities.pdf ",
  },
];
export const cr4_1_3 = [
  {
    name: "Link for photographs/ Geotagging of Campus facilities",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.1.3-Geotag-photos-final.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.1.3-List.pdf ",
  },
];
export const cr4_1_4 = [
  {
    name: "Link for additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.1.4.-B3-F.pdf",
  },
  {
    name: "Audited utilization statements (highlight relevant items)",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/4.1.4_1685772679_9034.pdf ",
  },
];
export const cr4_2_1 = [
  {
    name: "Link for the list of facilities available for patient care, teaching-learning and research",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.2.1-final.pdf",
  },
  {
    name: "Link for the facilities as per the stipulations of the respective Regulatory Bodies with Geotagging",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.2.1Geotag-Photos-Final.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/CIVIL-HOSPITAL-MOU-final.pdf ",
  },
];
export const cr4_2_2 = [
  {
    name: "Link to hospital records / Hospital Management Information System",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/4.2.2-OPD-OPDRegister.2.pdf ",
  },
];
export const cr4_2_3 = [
  {
    name: "Number of UG, PG students exposed to Laboratories, Animal House and Herbal Garden (in house OR hired) per year based on time-table and attendance",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/4.2.3_1686303516_9034.pdf",
  },
];
export const cr4_2_4 = [
  {
    name: "Any additional information",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/4.2.4_1686215639_9034.pdf",
  },
];
export const cr4_3_1 = [
  {
    name: "Link for geotagged photographs of library facilities",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.3.1-Geotag-Photos-Final.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.3.1-libraray-Software.pdf ",
  },
];
export const cr4_3_2 = [
  {
    name: "Link for geotagged photographs of library ambiance",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.3.2-Geotag-photos.pdf ",
  },
  {
    name: "Link for data on acquisition of books / journals /Manuscripts / ancient books etc., in the library.",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.3.2-B2-FINAL_compressed.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.3.2-Geotag-photos.pdf ",
  },
];
export const cr4_3_3 = [
  {
    name: "E-copy of subscription letter/member ship letter or related document with the mention of year",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/4.3.3_1685953744_9034.pdf ",
  },
];
export const cr4_3_4 = [
  {
    name: "Links for additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.3.4-B-3-FINAL-1.pdf",
  },
  {
    name: "Audit statement highlighting the expenditure for purchase of books and journal library resources",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/4.3.4_1685777267_9034.pdf ",
  },
];
export const cr4_3_5 = [
  {
    name: "Link for details of library usage by teachers and students",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/4.3.5-LibraryRegisterFinal.pdf",
  },
  {
    name: "Link for details of learner sessions / Library user programmes organized",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.3.5-Link-for-details-of-learner-sessions.pdf ",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.3.5-photos.pdf ",
  },
];
export const cr4_3_6 = [
  {
    name: "Give links e_content repository used by the teachers",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/4.3.6-B1.pdf ",
  },
];
// export const cr4_4_1 = [
//   {
//     name: "Criteria 4.1.1 - Curricular Aspects",
//     url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
//   },
// ];
export const cr4_4_2 = [
  {
    name: "Link for documents related to updation of IT and Wi-Fi facilities",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.4.2-final.pdf ",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.4.2-Geotag-Photos-final-05-06-2023.pdf",
  },
];
export const cr4_4_3 = [
  {
    name: "Criteria 4.1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const cr4_5_1 = [
  {
    name: "Provide extract of expenditure incurred on maintenance of physical facilities and academic support facilities duly certified by Chartered Accountant and the Head of the institution",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/4.5.1_1685790149_9034.pdf ",
  },
  {
    name: "Link for any additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/1.-4.5.1-B-Provide-extract-of-expenditure.pdf",
  },
];
export const cr4_5_2 = [
  {
    name: "Link for minutes of the meetings of the Maintenance Committee",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.5.2-Final-Upload.pdf",
  },
  {
    name: "Link for log book or other records regarding maintenance works",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/4.5.2-B2-FINAL.pdf ",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/4.5.2-Geotag-photos-Final.pdf ",
  },
];
