import React from "react";
import loadable from "@loadable/component";
import AboutCommitte from "../components/AboutPage/AboutCommitte";
import Prospectus from "../components/AboutPage/Prospectus";

const AboutCarousel = loadable(() =>
  import("../components/AboutPage/AboutCarousel")
);

const VisionMission = loadable(() =>
  import("../components/AboutPage/VisionMission")
);
const AboutComp = loadable(() => import("../components/HomePage/AboutComp"));
const AboutDetails = loadable(() =>
  import("../components/AboutPage/AboutDetails")
);

const About = () => {
  return (
    <>
      <AboutCarousel />

      <VisionMission />
      <AboutComp />
      <Prospectus />
      <AboutDetails />
      <AboutCommitte />
    </>
  );
};

export default About;
