export const periodontologyLink = [
    {
      name: "Dr. Rajhans Nilima",
      position: "Dean, Professor & HOD",
    },
    {
      name: "Dr. Moolya Nikesh",
      position: "Prof.",
    },
    {
      name: "Dr. Landge Nilima",
      position: "Prof",
    },
    {
      name: "Dr. Ajwani Himanshu",
      position: "Reader",
    },
    {
      name: "Dr. Shinde Sagar",
      position: "Reader",
    },
    {
      name: "Dr. Sabale Dhanesh",
      position: "Reader",
    },
    {
      name: "Dr. Sachin Funde",
      position: "Lecturer",
    },
    {
      name: "Lawande Asawari",
      position: "Lecturer",
    },
    {
      name: "Dr. Jadhav Swapnil",
      position: "Lecturer",
    },
    {
      name: "Dr. Berad Prajakta",
      position: "Tutor",
    },
    {
      name: "Dr. Kadam Bharat",
      position: "Tutor",
    },
    {
      name: "Dr. Sale Sachin",
      position: "Tutor",
    },
  ];