import React from "react";
import loadable from '@loadable/component'
import Prospectus from "../components/AdmissionPage/Prospectus2";


const HeaderSection = loadable(() => import('../components/AdmissionPage/headerSection'))
const HODSection = loadable(() => import('../components/AdmissionPage/HODSection'))
const StudentWelfare = loadable(() => import('../components/AdmissionPage/StudentWelfare'))


function Admission() {
  return (
    <>
      <HeaderSection />
      <HODSection />
      <StudentWelfare />
      <Prospectus />
    </>
  );
}

export default Admission;
