export const oralmrdLink = [
    {
      name: "Dr. Chandwani Roshan",
      position: "Reader & HOD",
    },
    {
      name: "Dr. Vaswani Dimple",
      position: "Lecturer",
    },
    {
      name: "Dr. Pawar Vinaya",
      position: "Lecturer",
    },
    {
      name: "Dr. Somare Sandip",
      position: "Tutor",
    },
    {
      name: "Dr. Karad Mahadeo",
      position: "Tutor",
    },
    {
      name: "Dr. Nagare Arum",
      position: "Tutor",
    },
    {
      name: "Dr. Nirbhavne Rutuja",
      position: "Tutor",
    },
    {
      name: "Dr. Tanpure Gauri",
      position: "Tutor",
    },
  ];
  