export const muhs = [
  {
    year: "2022-2023",
    sub: [
      {
        name: "Annexure-I",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/Annexure.pdf",
      },
      {
        name: "Annexure-II",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/ANNEXURE2.pdf",
      },
      {
        name: "Annexure-III",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/ANNEXURE3.pdf",
      },
      {
        name: "Annexure-IV",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/Annexure4_1.pdf",
      },
      {
        name: "Annexure-V",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/Annexure5.pdf",
      },
      {
        name: "Annexure-VI",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/Annexure6.pdf",
      },
      {
        name: "Annexure-VII",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/Annexure7.pdf",
      },
      {
        name: "Annexure-VIII",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/Annexure8.pdf",
      },
      {
        name: "Annexure-IX",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/ANNEXURE9.pdf",
      },
      {
        name: "Annexure-X",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/AnnexureX.pdf",
      },
      {
        name: "Annexure-XI",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/ANNEXURE11.pdf",
      },
      {
        name: "Annexure-XII",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/ANNEXURE12.pdf",
      },
      {
        name: "Annexure-XIII",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/Mandate_Annexure13.pdf",
      },
      {
        name: "Annexure-XIV",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/FinalAnnexure14.pdff",
      },
      {
        name: "Annexure-XV",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/Mandate_Annexure15.pdf",
      },
      {
        name: "Azadi_ka_amrutmohotsav",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/Azadi_ka_amrutmohotsav.pdf",
      },
      {
        name: "AISHE",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/AISHE-1.pdf",
      },
      {
        name: "PG & UG AFFILIATION",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/PG-UG-AFFILIATION-2022-23-1.pdf",
      },
    ],
  },
  {
    year: "2023-2024",
    sub: [
      {
        name: "Annexure-I",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/ANNEXURE1.pdf",
      },
      {
        name: "Annexure-II",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/2ANNEXURE.pdf",
      },
      {
        name: "Annexure-III",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/3ANNEXURE.pdf",
      },
      {
        name: "Annexure-IV",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/4AAnnexure.pdf",
      },
      {
        name: "Annexure-V",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/5ANNEXURE.pdf",
      },
      {
        name: "Annexure-VI",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/6ANNEXURE.pdf",
      },
      {
        name: "Annexure-VII",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/7ANNEXURE.pdf",
      },
      {
        name: "Annexure-VIII",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/8.-ANNEXURE-VIII.pdf",
      },
      {
        name: "Annexure-IX",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/9AAnnexure.pdf",
      },
      {
        name: "Annexure-X",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/10Annexure.pdf",
      },
      {
        name: "Annexure-XI",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/11ANNEXURE.pdf",
      },
      {
        name: "Annexure-XII",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/12ANNEXURE.pdf",
      },
      {
        name: "Annexure-XIII",
        pdf: 'https://files.yashdental.org/wp-content/uploads/2023/04/13ANNEXURE.pdf',
      },
      {
        name: "Annexure-XIV",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/14ANNEXURE.pdf",
      },
      {
        name: "Annexure-XV",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/15Mandate_Annexure.pdf",
      },
      {
        name: "Azadi_ka_amrutmohotsav",
        pdf: 'https://files.yashdental.org/wp-content/uploads/2023/04/Azadi_ka_amrutmohotsav-1.pdf',
      },
      {
        name: "SWACCHA_BHARAT_ABHIYAN",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/SWACCHA_BHARAT_ABHIYAN_PDF.pdf",
      },
      {
        name: "AISHE",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/04/AISHE-2.pdf",
      },
    ],
  },
  {
    year: "2024-2025",
    sub: [
      {
        name: "Annexure-I",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/1.-ANNEXURE-I-SEAT-MATRIX.pdf",
      },
      {
        name: "Annexure-II",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/2.-ANNEUXRE-II-INFRASTRUCTURE-DETAILS.pdf",
      },
      {
        name: "Annexure-III",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/3.-ANNEXURE-III-TRUST-DEEB-BYLAWS-REGISTRATION-CERTIFICATE.pdf",
      },
      {
        name: "Annexure-IV",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/4.-ANNEXURE-IV-LIBRARY-DETAILS.pdf",
      },
      {
        name: "Annexure-V",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/5.-ANNEXURE-V-DEPARTMENTWISE-EQUIPMENT.pdf",
      },
      {
        name: "Annexure-VI",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/6.-ANNEXURE-VI-CLINICAL-MATERIAL-IN-HOSPITAL.pdf",
      },
      {
        name: "Annexure-VII",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/7.-ANNEXURE-VII-INFORMATION-ABOUT-DEPARTMENT-WISE-OPD.pdf",
      },
      {
        name: "Annexure-VIII",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/8.-ANNEXURE-VIII-TOTAL-TEACHING-STAFF-INFO.-APPROVED-NOT-APPROVED.pdf",
      },
      {
        name: "Annexure-IX",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/9.-ANNEXURE-IX-SUBJECT-WISSE-TEACHER-LIST-APPROVED-NOT-APPROVED.pdf",
      },
      {
        name: "Annexure-X",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/10.-ANNEXURE-X-SUBJECT-WISE-MEDICAL-TEACHING-STAFF-LIST-UNDER-COURSE.pdf",
      },
      {
        name: "Annexure-XI",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/11.-ANNEXURE-XI-TOTAL-NON-TEACHING-STAFF-INFORMATION.pdf",
      },
      {
        name: "Annexure-XII",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/12.-ANNEXURE-XII-TOTAL-HOSPITAL-STAFF.pdf",
      },
      {
        name: "Annexure-XIII",
        pdf: 'https://files.yashdental.org/wp-content/uploads/2023/12/13.-ANNEXURE-XIII-_20231218_162933.pdf',
      },
      {
        name: "Annexure-XIV",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/14.-ANNEXURE-XIV-BIO-METRIC-ARTICLES.pdf",
      },
      {
        name: "Annexure-XV",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/15.-ANNEXURE-XV-SALARY-ASPECTS.pdf",
      },
      {
        name: "AISHE CERTIFICATE",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/AISHE-CERTIFICATE-C-13910.pdf",
      },
      {
        name: "Azadi ka amritmohotsav",
        pdf: "https://files.yashdental.org/wp-content/uploads/2023/12/Azadi-ka-amritmohotsav.pdf",
      },
    ],
  },
];
