export const CapabilityEnhancementandDevelopmentPrograms = [
    {
        pdfLink: "https://files.yashdental.org/wp-content/uploads/2023/06/Capability-Enhancement-and-Development-Programs.pdf"
    }
]

export const CareerGuidanceCellUjjwalBhavishyaInitiative = [
    {
        pdfLink: "https://files.yashdental.org/wp-content/uploads/2023/06/Career-Guidance-Cell-Ujjwal-Bhavishya-Initiative.pdf"
    }
]

export const ActionTakenReportforStudentFeedback = [
    {
        pdfLink: "https://files.yashdental.org/wp-content/uploads/2023/06/Action-Taken-Report-for-Student-Feedback.pdf"
    }
]
export const studentOnline = [
    {
        pdfLink: "https://files.yashdental.org/wp-content/uploads/2023/06/STUDENTS_ONLINE_RE.pdf"
    }
]
