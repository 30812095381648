
export const publicHealthLink = [
    {
      name: "Dr. Bomble Nikhil",
      position: "Reader",
    },
    {
      name: "Dr. Vyavahare Supriya",
      position: "Lecturer",
    },
    {
      name: "Dr. Khamkar Pradnya",
      position: "Tutor",
    },
    {
      name: "Dr. Shinde Pallavi",
      position: "Tutor",
    },
  ];