export const anatomyLink = [
    {
      name: "Dr. Vibhute Archana",
      position: "Reader & HOD",
    },
    {
      name: "Dr. Borude Ashvini",
      position: "Lecturer",
    },
    {
      name: "Dr. Dale Kunal",
      position: "Lecturer",
    },
    {
      name: "Dr. Kulkarni Mangesh",
      position: "Lecturer",
    },
    {
      name: "Dr. Devdhe Subhash",
      position: "Lecturer",
    },
  ];