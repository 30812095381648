export const departCompImageLabel = [
  {
    name: "Prosthodontics",
    linkUrl: "/department/prosthodontics",
    label:
      "https://res.cloudinary.com/dv8400fc2/image/upload/v1679939318/YC%20dental%20clg/prosthodontics_logo_vm2kdz.jpg",
  },
  {
    name: "Orthodontics",
    linkUrl: "/department/orthodontics",
    label:
      "https://res.cloudinary.com/dv8400fc2/image/upload/v1679939318/YC%20dental%20clg/orthodontics_logo_bm7afc.png",
  },
  {
    name: "Periodontics",
    linkUrl: "/department/periodontics",
    label:
      "https://res.cloudinary.com/dv8400fc2/image/upload/v1679939317/YC%20dental%20clg/periodontics_logo_vdxpqn.jpg",
  },
  {
    yellowColor: true,
    name: "Conservative Dentistry and Endodontics",
    linkUrl: "/department/conservative-dentistry-endodontics",
    label:
      "https://res.cloudinary.com/dv8400fc2/image/upload/v1679939318/YC%20dental%20clg/conservative_udfkmu.png",
  },
  {
    yellowColor: true,
    name: "Oral Medicine & Radiology",
    linkUrl: "/department/oral-medicine-radiology",
    label:
      "https://res.cloudinary.com/dv8400fc2/image/upload/v1679939317/YC%20dental%20clg/oral_medicine_and_radiology_jczhpz.jpg",
  },
  {
    yellowColor: true,
    name: "Pedodontics",
    linkUrl: "/department/pedodontics",
    label:
      "https://res.cloudinary.com/dv8400fc2/image/upload/v1679939317/YC%20dental%20clg/pedodontics_logo_jmo0ym.png",
  },

  {
    yellowColor: true,
    name: "Oral and Maxillofacial Surgery",
    linkUrl: "/department/oral-maxillofacial-surgery",
    label:
      "https://res.cloudinary.com/dv8400fc2/image/upload/v1679939319/YC%20dental%20clg/oral_surgery_jlfsiz.png",
  },

  {
    yellowColor: true,
    name: "Public Health Dentistry",
    linkUrl: "/department/public-health-dentistry",
    label:
      "https://res.cloudinary.com/dv8400fc2/image/upload/v1679939318/YC%20dental%20clg/public_health_dentistry_cqpifh.png",
  },

  {
    yellowColor: true,
    name: "Oral Pathology",
    linkUrl: "/department/oral-pathology",
    label:
      "https://res.cloudinary.com/ddaalka6i/image/upload/v1679720624/Acdemic%20PAge/Department%20Comp/OralPath_hkzma0.png",
  },

  {
    yellowColor: false,
    name: "General Medicine",
    linkUrl: "/department/gen-medicine",
    label:
      "https://res.cloudinary.com/ddaalka6i/image/upload/v1679726330/Acdemic%20PAge/Department%20Comp/GeneralMd_ffxxoq.png",
  },
  {
    yellowColor: false,
    name: "General Surgery",
    linkUrl: "/department/gen-surgery",
    label:
      "https://res.cloudinary.com/ddaalka6i/image/upload/v1679721062/Acdemic%20PAge/Department%20Comp/GeneralSur_r3poat.png",
  },

  {
    yellowColor: false,
    name: "General Pathology",
    linkUrl: "/department/panthology",
    label:
      "https://res.cloudinary.com/ddaalka6i/image/upload/v1679721062/Acdemic%20PAge/Department%20Comp/GeneralPAthology_afpgdr.png",
  },
  {
    yellowColor: false,
    name: "Biochemistry",
    linkUrl: "/department/biochemistry",
    label:
      "https://res.cloudinary.com/ddaalka6i/image/upload/v1679721062/Acdemic%20PAge/Department%20Comp/BioChemistry_plagul.png",
  },
  {
    yellowColor: false,
    name: "Anatomy",
    linkUrl: "/department/anotomy",
    label:
      "https://res.cloudinary.com/ddaalka6i/image/upload/v1679721062/Acdemic%20PAge/Department%20Comp/EGenralAnatomy_xnnedo.png",
  },
  {
    yellowColor: false,
    name: "Physiology",
    linkUrl: "/department/physiology",
    label:
      "https://res.cloudinary.com/ddaalka6i/image/upload/v1679726330/Acdemic%20PAge/Department%20Comp/GeneralMd_ffxxoq.png",
  },
  {
    yellowColor: true,
    name: "Pharmacology",
    linkUrl: "/department/pharma",
    label:
      "https://res.cloudinary.com/ddaalka6i/image/upload/v1679721062/Acdemic%20PAge/Department%20Comp/GeneralPAthology_afpgdr.png",
  },
  {
    yellowColor: true,
    name: "Microbiology",
    linkUrl: "/department/microbiology",
    label:
      "https://res.cloudinary.com/ddaalka6i/image/upload/v1679721062/Acdemic%20PAge/Department%20Comp/GeneralSur_r3poat.png",
  },
  {
    yellowColor: true,
    name: "Anesthesia",
    linkUrl: "/department/anesthesia",
    label:
      "https://res.cloudinary.com/ddaalka6i/image/upload/v1679720624/Acdemic%20PAge/Department%20Comp/OralPath_hkzma0.png",
  },
];
