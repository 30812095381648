export const generalSurgeryLink = [
    {
      name: "Dr. Jadhav Bhaskar",
      position: "Reader & HOD",
    },
    {
      name: "Dr. Nagargoje Vaishali",
      position: "Lecturer",
    },
    {
      name: "Dr. Wakpanjar Jayshri",
      position: "Lecturer",
    },
    {
      name: "Dr. Shirsat Sagar",
      position: "Lecturer",
    },
  ];