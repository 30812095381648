export const microbiologyLink = [
    {
      name: "Dr. Karad Swati",
      position: "Reader ",
    },
    {
      name: "Dr. Joshi Milind",
      position: "Lecturer",
    },
    {
      name: "Dr. Kabra Dipakkumar R",
      position: "Lecturer",
    },
  ];