/*Student Support */
export const _5_1_1 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _5_1_2 = [
  {
    name: "Capability Enhancement and Development Schemes",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/Capability-Enhancement-and-Development-Programs.pdf",
  }
];

export const _5_1_3 = [
  {
    name: "Training and Guidance for Competitive Examinations and Career Counseling",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/Career-Guidance-Cell-Ujjwal-Bhavishya-Initiative.pdf",
  }
];

export const _5_1_4 = [
  {
    name: "The Institution has an active international student cell",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/5.1.4.-B1.pdf",
  },
  
];


export const _5_1_5 = [
  {
    name: "Circular/web-link/ committee report justifying the objective of the metric",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/5.1.5.-B2.pdf",
  }
];


/*Student Progression */
export const _5_2_1 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _5_2_2 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];


export const _5_2_3 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];



/*Student Participtopn and Activities */

export const _5_3_1 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _5_3_2 = [
  {
    name: "Link for reports on the student council activities",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/5.3.2.-Reports-of-student-council-activities.pdf",
  },
  {
    name: "Geo-tagged photographs",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/5.3.2.-Geotagged-Photos.pdf",
  },
  {
    name: "Any additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/5.3.2.-B3.pdf",
  },
  {
    name: "Any Other Upload File",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/5.3.2-List-of-Students-Council-Activitis.pdf",
  },
  {
    name: "Any Other Upload File",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/5.3.2-List-of-Participants.pdf",
  }
];

export const _5_3_3 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

/*Alunini Engagement */

export const _5_4_1 = [
  {
    name: "Link for frequency of meetings of Alumni Association with minutes",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/5.4.1.-B3.Frequency-of-Meeting.pdf",
  },
  {
    name: "Link for details of Alumni Association activities",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/5.4.1.-B2.DetailsOfActivities-link.pdf",
  },
  {
    name: "Link for audited statement of accounts of the Alumni Association",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/5.4.1.-B5.AuditiedStatement.pdf",
  },
  {
    name: "Link for Additional Information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/5.4.1.-B1.Registration_Additional.pdf",
  },
  {
    name: "Link for Quantum of financial contribution",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/5.4.1.-B5.AuditiedStatement.pdf",
  },
];

export const _5_4_2 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];


