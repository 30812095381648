export const _8_1_1 = [
    {
      name: "Criteria 1.1 - Curricular Aspects",
      url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
    },
    
];

export const _8_1_2 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },

];

export const _8_1_3 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },

];

export const _8_1_4 = [
  {
    name: "Programme report",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/8.1.4-weblink-final.pdf",
  },
  {
    name: "Orientation circulars",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/8.1.4_1686314004_9034.pdf",
  },
];


export const _8_1_5 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  }
];


export const _8_1_6 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.3 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.4 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.5 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.6 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.7 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.8 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];


export const _8_1_7 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  
];

export const _8_1_8 = [
  {
    name: "Report on the list and steps taken by the College to measure attainment of specific competencies by the BDS students/interns stated in the undergraduate curriculum during the last five years",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/8.1.8_1686135025_9034.pdf",
  },
  {
    name: "List of competencies",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/8.1.8_1686135018_9034.pdf",
  },
  {
    name: "Geotagged photographs of the objective methods used like OSCE/OSPE",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/8.1.8_1686135046_9034.pdf",
  },
  {
    name: "Any other relevant information",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/8.1.8_1686135051_9034.pdf",
  },
  {
    name: "Upload Other Files: 1",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/8.1.8_1684920044_9034.pdf",
  },
];


export const _8_1_9 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.3 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.4 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.5 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.6 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.7 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.8 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _8_1_10 = [
  {
    name: "Dental graduate attributes as described in the website of the College.",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/8.1.10_1686135513_9034.pdf ",
  },
  {
    name: "Any other relevant information",
    url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/8.1.10_1686137343_9034.pdf ",
  },

 
];



export const _8_1_11 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.3 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.4 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.5 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.6 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.7 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.8 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _8_1_12 = [
  {
    name: "List of teachers year-wise who participated in the seminars/conferences/ workshops on emerging trends in Medical Educational technology organized by the DEU of the College during the last five years",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/8.1.12-INDEX-B1-List-Of-teachers-attending-program-merged.pdf",
  },
  {
    name: "List of seminars/conferences/workshops on emerging trends in Dental Educational Technology organized by the DEU year-wise during the last five years.",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/8.1.12-INDEX-B2-program-list.pdf",
  },
  {
    name: "Any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/8.1.12-INDEX-B3-any-other-merged.pdf",
  },
 
];