export const pdfRow = [
  {
    name: "IQAC",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681373226/YCDENTAL/pdf/NAAC/new-IQAC1_vihfwa.pdf",
  },
];
export const pdfRowIIQA = [
  {
    name: "Self declaration",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Undertaking",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978117/YCDENTAL/pdf/NAAC/Undertaking23.03.2023_kzwbk3.pdf",
  },
  
];
