export const pharmacologyLink = [
    {
      name: "Dr. Khanzode Suchet",
      position: "Reader & HOD",
    },
    {
      name: "Dr. Ingle Preeti",
      position: "Lecturer",
    },
    {
      name: "Dr. Pardeshi Vijay",
      position: "Lecturer",
    },
    {
      name: "Dr. Aghav Prakash",
      position: "Lecturer",
    },
    {
      name: "Dr. Ghughe Seema",
      position: "Lecturer",
    },
  ];
  