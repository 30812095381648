/*Institutional Vsison and leadership */
export const _6_1_1 = [
  {
    name: "Link for Vision and Mission documents approved by the College bodies",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.1.1B1_VisionMission.pdf",
  },
  {
    name: "Link for additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.1.1B3AnyOtherValueStatement.pdf",
  },
  {
    name: "Link for achievements which led to Institutional excellence",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.1.1B2-Achievements-which-led-to-Institutional-excellence.pdf",
  },
  {
    name: "Any additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/6.1.1_1684996824_9034.pdf",
  }
];

export const _6_1_2 = [
  {
    name: "Link for relevant information / documents",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.1.2B1Organogram.pdf",
  },
  {
    name: "Link for additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/6.1.2-B2-AnyOtherRelevant.pdf",
  },
];

/*2 Strategy Development and Deployment  */

export const _6_2_1 = [
  {
    name: "Link for Strategic Plan documents",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.2.1-Strategic-Plan.pdf",
  },
  {
    name: "Link for organizational structure",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.2.1B1OrganisationalStructure.pdf",
  },
  {
    name: "Link for minutes of the College Council/ other relevant bodies for deployment/ deliverables of the strategic plan",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.2.1-LMC.pdf",
  },
  {
    name: "Link for additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.2.1B4-AnyOtherRelevant.pdf",
  },
  {
    name: "Any additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/6.2.1_1684996916_9034.pdf",
  }
];

export const _6_2_2 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

/*Faculty Empowerment Strategies  */
export const _6_3_1 = [
  {
    name: "Link for policy document on the welfare measures",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.3.1-Link-for-policy-document.pdf",
  },
  {
    name: "Link for list of beneficiaries of welfare measures",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.3.1-List-of-Beneficiaries.pdf",
  },
  {
    name: "Link for additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.3.1-Any-other-relevant-document.pdf",
  },
];

export const _6_3_2 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _6_3_3 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _6_3_4 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _6_3_5 = [
  {
    name: "Link for performance Appraisal System",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/6.3.5-PerformanceApraisal-Final.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.3.5B2.AnyOtherRelevant.pdf",
  },
  {
    name: "Any additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/6.3.5AnyAdditionalInformation.pdf",
  },
];

/*Financial Management and Resource Mobilization  */
export const _6_4_1 = [
  {
    name: "Link for performance Appraisal System",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/6.3.5-PerformanceApraisal-Final.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.3.5B2.AnyOtherRelevant.pdf",
  },
  {
    name: "Any additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/6.3.5AnyAdditionalInformation.pdf",
  },
];
export const _6_4_2 = [
  {
    name: "Link for documents pertaining to internal and external audits year-wise for the last five years",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.4.2-DocPertainingInternalAudit-Final.pdf",
  }
];
export const _6_4_3 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

/* Internal Quality Assurance System */
export const _6_5_1 = [
  {
    name: "Link for the structure and mechanism for Internal Quality Assurance",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.5.1B1StructureMechanism.pdf",
  },
  {
    name: "Link for minutes of the IQAC meetings",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/6.5.1-IQAC_MoM-Final.pdf",
  },
  {
    name: "Link for any other relevant information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/05/6.5.1_Circulars.pdf",
  },
  {
    name: "Any additional information",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/6.5.1_1684401643_9034.pdf",
  },
  {
    name: "Any Other Upload Files",
    url: "https://files.yashdental.org/wp-content/uploads/2023/06/6.5.1_2.pdf",
  }
];

export const _6_5_2 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];

export const _6_5_3 = [
  {
    name: "Criteria 1.1 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
  {
    name: "Criteria 1.2 - Curricular Aspects",
    url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
  },
];
