export const cr7_1_1 = [
    {
      name: "Criteria 1.8 - Curricular Aspects",
      url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
    },
  ];

  export const cr7_1_2 = [
    {
      name: "Specific facilities provided for women in terms of a. Safety and security b. Counselling c. Common Rooms d. Day care centre for young children",
      url: "https://files.yashdental.org/wp-content/uploads/2023/06/7.1.2-B2-Specific-facilities-provided-for-women.pdf ",
    },
    
    {
      name: "Link for any other relevant information",
      url: "https://files.yashdental.org/wp-content/uploads/2023/06/7.1.2-b3-women-empowerment-cell-policy.pdf ",
    },
    {
      name: "Any additional information",
      url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/7.1.2_1685430775_9034.pdf",
    },
    
    {
      name: "Annual gender sensitization action plan",
      url: "https://files.yashdental.org/wp-content/uploads/2023/06/7.1.2-ANNUAL-GENDER-SENSITIZATION-PLAN.pdf ",
    },
  ];

  export const cr7_1_3 = [
   
    {
      name: "Criteria 1.7 - Curricular Aspects",
      url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
    },
    {
      name: "Criteria 1.8 - Curricular Aspects",
      url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
    },
  ];

  export const cr7_1_4 = [
   
    {
      name: "Link for relevant documents like agreements/MoUs with Government and other approved agencies",
      url: "https://files.yashdental.org/wp-content/uploads/2023/06/7.1.4-B1-BMW-AGGREEMENT-WITH-GOVERNMENT-AGENCY-Final.pdf",
    },
    {
      name: "Link for geotagged photographs of the facilities",
      url: "https://files.yashdental.org/wp-content/uploads/2023/06/7.1.4-b2-geo-tagged-photos-of-bmw.pdf",
    },
    {
      name: "Link for any other relevant information",
      url: "https://files.yashdental.org/wp-content/uploads/2023/06/7.1.4B3-CIRCULARS-MOM-REPORTS-BMW-_Final.pdf",
    },
    {
      name: "Any additional information",
      url: "https://assessmentonline.naac.gov.in/storage/app/hei/SSR/111014/7.1.4_1684406921_9034.pdf ",
    },
  ];

  export const cr7_1_5 = [
   
    {
      name: "Criteria 1.7 - Curricular Aspects",
      url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
    },
    {
      name: "Criteria 1.8 - Curricular Aspects",
      url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
    },
  ];

  export const cr7_1_6 = [
   
    {
      name: "Criteria 1.7 - Curricular Aspects",
      url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
    },
    {
      name: "Criteria 1.8 - Curricular Aspects",
      url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
    },
  ];

  export const cr7_1_7 = [
   
    {
      name: "Criteria 1.7 - Curricular Aspects",
      url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
    },
    {
      name: "Criteria 1.8 - Curricular Aspects",
      url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
    },
  ];
  export const cr7_1_8 = [
   
    {
      name: "Link for supporting documents on the information provided (as reflected in the administrative and academic activities of the Institution)",
      url: "https://files.yashdental.org/wp-content/uploads/2023/06/7.1.8-REPORTS_pagenumber-_Final.pdf",
    },
    {
      name: "Link for any other relevant information/documents",
      url: "https://files.yashdental.org/wp-content/uploads/2023/06/7.1.8MOM-AND-NOTICE_pagenumber_Final.pdf",
    },
  ];
  export const cr7_1_9 = [
   
    {
      name: "Web link of the code of conduct",
      url: "https://files.yashdental.org/wp-content/uploads/2023/05/7.1.9-CODE-OF-CONDUCT-.pdf",
    }
  ];
  export const cr7_1_10 = [
   
    {
      name: "Criteria 1.7 - Curricular Aspects",
      url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
    },
    {
      name: "Criteria 1.8 - Curricular Aspects",
      url: "https://res.cloudinary.com/dbdni9n4i/image/upload/v1681978118/YCDENTAL/pdf/NAAC/Self_Declaration23.03.2023_mfrpv7.pdf",
    },
  ];
  export const cr7_2_1 = [
   
    {
      name: "Link for best practices page in the Institutional web site",
      url: "https://files.yashdental.org/wp-content/uploads/2023/06/7.2-BEST-PRACTICES-WITH-HERBAL-GARDEN.pdf",
    },
    {
      name: "Link for any other relevant information",
      url: "https://files.yashdental.org/wp-content/uploads/2023/06/7.2-best-practice-mou.pdf ",
    },
  ];
  export const cr7_3_1 = [
   
    {
      name: "Link for appropriate web page in the institutional website",
      url: "https://files.yashdental.org/wp-content/uploads/2023/06/Best-Practices.pdf",
    },
    {
      name: "Link for any other relevant information",
      url: "https://files.yashdental.org/wp-content/uploads/2023/06/7.3.INOVATIVE-TEACHINGS_pagenumber.pdf ",
    },
  ];

