export const prosthodonticsLink = [
    {
      name: "Dr. Amrita Pandit ",
      position: "Prof. & HOD",
    },
    {
      name: "Dr. V. N. V. Madhav",
      position: "Prof.",
    },
    {
      name: "Dr. Aruna Bhandari",
      position: "Prof.",
    },
    {
      name: "Dr. Kulkarni Manisha",
      position: "Reader",
    },
    {
      name: "Dr. Karande Nilima",
      position: "Reader",
    },
    {
      name: "Dr. Manasi Oswal",
      position: "Reader",
    },
    {
      name: "Dr. Raikar Sonal",
      position: "Reader",
    },
    {
      name: "Dr. Nalawade Sumit",
      position: "Lecturer",
    },
    {
      name: "Dr. Attal Pratik",
      position: "Lecturer",
    },
    {
      name: "Dr. Ugale Vishakha",
      position: "Lecturer",
    },
    {
      name: "Dr. Omkar Sakpal",
      position: "Lecturer",
    },
    {
      name: "Dr. Pratik Hodar",
      position: "Lecturer",
    },
    {
      name: "Dr. Mohammad Wasimuddin",
      position: "Lecturer",
    },
    {
      name: "Dr. Motadu Prathamesh",
      position: "Lecturer",
    },
    {
      name: "Dr. Kandi Nikita",
      position: "Lecturer",
    },
    {
      name: "Dr. Wadkar Saili",
      position: "Lecturer",
    },
    {
      name: "Dr. Gorde Krishna",
      position: "Lecturer",
    },
    {
      name: "Dr Pillewar Anjusha",
      position: "Lecturer",
    },
    {
      name: "Dr. Tellawar Shilpa",
      position: "Tutor",
    },
    {
      name: "Dr. Jaggi Dimpal",
      position: "Tutor",
    },
    {
      name: "Dr. Gupta Pooja",
      position: "Tutor",
    },
    {
      name: "Dr. Kavita Shirsath",
      position: "Tutor",
    },
  ];