export const biochemistryLink = [
    {
      name: "Dr. Gaikwad Sudin",
      position: "Reader",
    },
    {
      name: "Mrs. Aradhye Anuradha",
      position: "Lecturer",
    },
    {
      name: "Dr. Saruk Bhagyashree",
      position: "Lecturer",
    },
  ];