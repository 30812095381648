export const physiologyLink = [
    {
      name: "Dr. Patil Vaishali",
      position: "Reader & HOD",
    },
    {
      name: "Dr. Lande Sudam",
      position: "Lecturer",
    },
    {
      name: "Mrs. Ambre Heena",
      position: "Lecturer",
    },
  ];